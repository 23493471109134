import { env } from '../../../env';
import { fetcherConfig } from '../../../contexts/AuthContext/AuthContext';
import { GraphQLClient, RawRequestOptions, Variables } from 'graphql-request';
// import { logError } from '../../../utils/logging';

// This method signature is compatible with:
// https://www.graphql-code-generator.com/plugins/typescript-react-query#usage-example-isreacthook-false
export function fetcher<Data, V extends Variables>(
  document: string,
  variables?: V,
  options?: RequestInit['headers'],
): (context?: { signal?: AbortSignal }) => Promise<Data> {
  return async function fetchData({ signal } = {}) {
    // Appending the document name to the URL is not used by the server, but it
    // facilitates troubleshooting issues via browser dev tools or Sentry.
    const [, , name] = /(query|mutation) +([^ ({]*) *[({]/i.exec(document) ?? [];
    const url = `${env.REACT_APP_ECOMMERCE_GRAPHQL_API_URL}?${encodeURIComponent(name)}`;
    const headers = await fetcherConfig.getHeaders();

    const client = new GraphQLClient(url, {
      headers: {
        ...headers,
        ...(options ?? {}),
      },
      signal: signal as RawRequestOptions['signal'],
    });

    try {
      const response = await client.rawRequest<Data, V>(document, variables);
      return response.data;
    } catch (err) {
      // AbortError is a request being cancelled, typically because the
      // component performing it is being unmounted due to user action.
      if ((err as Error).name !== 'AbortError') {
        // logError(err, { variables });
        console.error(err, { variables });
      }
      throw err;
    }
  };
}
