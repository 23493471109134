import { AdminSplitOrderMutation } from '../../../../api-clients/falcon-api/graphql/mutations/splitOrder.generated';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { OmsLink } from './OmsLink';
import { OrderForSplitLineItemType, OrderForSplitType } from '../../../../hooks/useGetOrderForSplit';
import { RiCheckboxCircleLine, RiErrorWarningLine, RiInformationLine } from '@remixicon/react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

type SubmitModalProps = {
  items: NonNullable<OrderForSplitLineItemType>;
  order: NonNullable<OrderForSplitType>;
  onCancel: () => void;
  onSubmit: () => Promise<AdminSplitOrderMutation['adminSplitOrder']>;
};

export function SubmitModal({ items, order, onCancel, onSubmit }: SubmitModalProps) {
  const [flowState, setFlowState] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');
  const [result, setResult] = useState<{
    id: string;
    error: NonNullable<AdminSplitOrderMutation['adminSplitOrder']>['error'] | null;
  }>({ id: '', error: null });

  const submit = async () => {
    setFlowState('loading');
    try {
      const adminSplitOrder = await onSubmit();
      if (adminSplitOrder?.order) {
        setFlowState('success');
        setResult({ id: adminSplitOrder.order.friendlyId!, error: null });
      } else if (adminSplitOrder?.error) {
        setFlowState('error');
        setResult({ id: '', error: adminSplitOrder.error });
      }
    } catch (error) {
      setFlowState('error');
      setResult({ id: '', error: error as Error });
    }
  };

  return (
    <Dialog open TransitionComponent={Slide}>
      <Box>
        {(() => {
          switch (flowState) {
            case 'initial':
              return (
                <Box padding={4}>
                  <Box textAlign="center" width="100%">
                    <RiInformationLine color={ColorsV2.gray_medium} size="64px" />
                  </Box>
                  <Box padding={1}>
                    <Typography variant="h6">
                      Following items will be moved from order {order.friendlyId} to a new order:
                    </Typography>
                    <ul className="px-4 py-2">
                      {items.map((item) => {
                        return (
                          <li key={item.id}>
                            <Typography fontWeight={600} lineHeight="16px">
                              {item.hcpcsCode?.code}
                            </Typography>
                            <Typography variant="body2">{item.displayText}</Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                  <Box display="flex" gap={1} justifyContent="flex-end">
                    <Button onClick={onCancel} type="button" variant="secondary">
                      Cancel
                    </Button>
                    <Button onClick={submit} type="button" variant="primary">
                      Confirm
                    </Button>
                  </Box>
                </Box>
              );
            case 'loading':
              return (
                <Box padding={8} textAlign="center">
                  <CircularProgress color="primary" size={64} />
                </Box>
              );
            case 'success':
              return (
                <Box padding={4}>
                  <Box textAlign="center" width="100%">
                    <RiCheckboxCircleLine color={ColorsV2.green} size="64px" />
                  </Box>
                  <Box>
                    <Typography variant="h6">Original order {order.friendlyId} was split into following:</Typography>
                    <List sx={{ listStyleType: 'disc' }}>
                      <ResultListElement id={order.friendlyId!} type="original" />
                      <ResultListElement id={result.id} type="new" />
                    </List>
                  </Box>
                  <span className="text-muted-foreground">
                    Please return to OMS using one of the links above to continue your workflow
                  </span>
                </Box>
              );
            case 'error':
              return (
                <Box padding={4}>
                  <Box textAlign="center" width="100%">
                    <RiErrorWarningLine color={ColorsV2.mustard} size="64px" />
                  </Box>
                  <Box>
                    <Typography variant="h6">Something went wrong</Typography>
                    <Typography>{result.error?.message ?? 'Unknown Error'}</Typography>
                  </Box>
                  <Box textAlign="end">
                    <Button onClick={onCancel} type="button" variant="primary">
                      Ok
                    </Button>
                  </Box>
                </Box>
              );
            default:
              return null;
          }
        })()}
      </Box>
    </Dialog>
  );
}

function ResultListElement({ id, type }: { id: string; type: string }) {
  return (
    <ListItem sx={{ display: 'list-item', listStylePosition: 'inside' }}>
      <span>
        {id} ({type})
      </span>
      <OmsLink id={id} />
    </ListItem>
  );
}
