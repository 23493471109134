import { z } from 'zod';

const createEnv = () => {
  const clientPrefix = 'REACT_APP_';
  const runtimeEnv = import.meta.env;
  // const isProd = runtimeEnv.MODE === 'production';

  const EnvSchema = z.object({
    REACT_APP_ENV: z.enum(['production', 'staging', 'preview', 'qa', 'development']),
    REACT_APP_ECOMMERCE_GRAPHQL_API_URL: z.string().url().min(1),
    REACT_APP_COMMIT_REF: z.string().min(1).optional(),
    REACT_APP_GOOGLE_CLIENT_ID: z.string().min(1),
    REACT_APP_SMARTY_STREETS_PUBLIC_KEY: z.string().min(1),
  } satisfies Record<`${typeof clientPrefix}${string}`, z.ZodTypeAny>);

  const envVars = Object.entries(runtimeEnv).reduce<Record<string, string>>((acc, curr) => {
    const [key, value] = curr;
    if (key.startsWith(clientPrefix)) {
      acc[key] = value;
    }

    return acc;
  }, {});

  const parsedEnv = EnvSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided.
The following variables are missing or invalid:
${Object.entries(parsedEnv.error.flatten().fieldErrors)
  .map(([k, v]) => `- ${k}: ${v.toString()}`)
  .join('\n')}
`,
    );
  }

  return parsedEnv.data;
};

export const env = createEnv();
