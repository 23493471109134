import { RiArrowLeftLine, RiArrowRightLine } from '@remixicon/react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export const DirectionButton = ({
  onClick,
  direction,
  disabled,
  hint,
}: {
  onClick: () => void;
  direction: 'left' | 'right';
  disabled: boolean;
  hint?: string;
}) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      placement={direction === 'left' ? 'bottom' : 'top'}
      title={hint ?? `Move item to ${direction === 'left' ? 'original' : 'new'} order`}
    >
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '6px',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.09)',
          margin: '8px 0',
        }}
      >
        <IconButton
          disabled={disabled}
          onClick={onClick}
          sx={{
            borderRadius: 'inherit',
          }}
        >
          {direction === 'left' ? <RiArrowLeftLine size="14px" /> : <RiArrowRightLine size="14px" />}
        </IconButton>
      </Box>
    </Tooltip>
  );
};
