import { createContext, useContext, useEffect, useState } from 'react';
import { GoogleClient } from './GoogleClient';
import { LoginScreen } from '../../screens/LoginScreen';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

export type HttpHeaders = Record<string, string>;

// this is necessary because fetcher() is a static method with no proper way to
// get access to the authState in React context. A better solution would be to
// change the fetcher config in codegen.ts to `isReactHook: true` option
// https://www.graphql-code-generator.com/plugins/typescript-react-query#usage-example-isreacthook-true
export const fetcherConfig = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async getHeaders(): Promise<HttpHeaders> {
    return {};
  },
};

export interface AuthMeta {
  headers: HttpHeaders;
}

export type AuthState =
  | { status: 'loading' }
  | { status: 'error'; error: Error }
  | { status: 'anonymous'; login(redirectUrl: string): void }
  | { status: 'authenticated'; logout?(returnTo: string): void; getMeta(): Promise<AuthMeta>; authType: string };

export const AuthContext = createContext<AuthState>({ status: 'loading' });

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuthController();
  const location = useLocation();
  const navigate = useNavigate();

  // Private route protection is done as high in the stack as possible to
  // expedite rerouting to Auth0. This avoids loading a lot of extraneous
  // JS and React components just to immediately redirect to the login page.

  const isPrivateRoute = ['/*'].some((path) => matchPath(path, location.pathname));

  useEffect(() => {
    if (isPrivateRoute && auth.status === 'anonymous') {
      navigate(window.location.pathname + window.location.search);
    }
  }, [auth.status, isPrivateRoute, navigate]);

  // Wait for Auth callback (if there is one from Auth0) to finish
  // before loading the App and potentially redirecting to the 404 page.
  const isAuthCallback = ['/callback'].some((path) => matchPath(path, location.pathname));

  // Error state is allowed to unblock <App /> rendering so that it can log
  // errors to Sentry. App is then responsible for blocking child rendering.
  // Alternatively we could lazy load and render a dedicated auth error view
  if (isAuthCallback || (isPrivateRoute && auth.status !== 'authenticated' && auth.status !== 'error')) {
    if (auth.status === 'anonymous') {
      return <LoginScreen onLogin={() => auth.login('/')} />;
    }
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useAuthController() {
  const [authState, setAuthState] = useState<AuthState>({ status: 'loading' });
  const navigate = useNavigate();
  const location = useLocation();

  const googleLogin = useGoogleLogin({
    onError(errorResponse) {
      console.error('Error logging in with Google:', errorResponse);
    },
    onNonOAuthError(nonOAuthError) {
      console.error('Non-OAuth error logging in with Google:', nonOAuthError);
    },
    flow: 'auth-code',
    ux_mode: 'redirect',
    state: window.location.href,
    redirect_uri: `${window.location.origin}/.netlify/functions/callback`,
  });

  useEffect(() => {
    function executeAuthFlow(signal: AbortSignal) {
      try {
        const result = authViaGoogle(navigate, location, googleLogin);

        // getHeaders should be set before setAuthState so that calls such as
        // getUser immediately have access to new headers. Ideally, the fetcher
        // function would be a React hook and not need this global variable.
        fetcherConfig.getHeaders = async () => {
          try {
            return result.status === 'authenticated' ? (await result.getMeta()).headers : {};
          } catch (err) {
            // logError(err);
            throw new Error('Unauthorized', { cause: err });
          }
        };

        if (!signal.aborted) setAuthState(result);
      } catch (error) {
        if (!signal.aborted) setAuthState({ status: 'error', error: error as Error });
      }
    }

    const abort = new AbortController();
    executeAuthFlow(abort.signal);
    return () => abort.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authState;
}

function authViaGoogle(
  _navigate: ReturnType<typeof useNavigate>,
  _location: ReturnType<typeof useLocation>,
  loginCb: ReturnType<typeof useGoogleLogin>,
): AuthState {
  const client = new GoogleClient();

  function logout(returnTo: string) {
    client.logout({ logoutParams: { returnTo } });
  }

  function login() {
    loginCb();
  }

  async function getMeta(): Promise<AuthMeta> {
    let token: string | undefined;
    try {
      token = await client.getTokenSilently();
    } catch (_err) {
      login();
    }

    return { headers: { Authorization: `Bearer Google:${token}` } };
  }

  return client.isAuthenticated()
    ? { status: 'authenticated', logout, getMeta, authType: 'google' }
    : { status: 'anonymous', login };
}
