import {
  AdminSplitOrderMutationVariables,
  useAdminSplitOrderMutation,
} from '../../../../api-clients/falcon-api/graphql/mutations/splitOrder.generated';
import { DirectionButton } from './DirectionButton';
import { OrderForSplitLineItemType, OrderForSplitType } from '../../../../hooks/useGetOrderForSplit';
import { SelectList } from './SelectList';
import { SubmitModal } from './SubmitModal';
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export function OrdersForm({ order }: { order: NonNullable<OrderForSplitType> }) {
  const [originalItems, setOriginalItems] = useState(order?.lineItems);
  const [newOrderItems, setNewOrderItems] = useState<OrderForSplitLineItemType>([]);

  const [checkedItemsLeft, setCheckedItemsLeft] = useState<Record<string, boolean>>({});
  const [checkedItemsRight, setCheckedItemsRight] = useState<Record<string, boolean>>({});

  const [modalState, setModalState] = useState('closed');

  const split = useAdminSplitOrderMutation();

  const handleItemCheck = useCallback(
    (item: string, setFunction: (items: (prev: Record<string, boolean>) => Record<string, boolean>) => void) => {
      setFunction((prev) => ({
        ...prev,
        [item]: !prev[item],
      }));
    },
    [],
  );

  const resetState = () => {
    setOriginalItems(order?.lineItems);
    setNewOrderItems([]);
    setCheckedItemsLeft({});
    setCheckedItemsRight({});
  };

  const moveToOrder = (
    from: OrderForSplitLineItemType,
    to: OrderForSplitLineItemType,
    selected: Record<string, boolean>,
    setFrom: (items: OrderForSplitLineItemType) => void,
    setTo: (items: OrderForSplitLineItemType) => void,
  ) => {
    setFrom(from.filter((item) => !selected[item.id]));
    setTo([...to, ...from.filter((item) => selected[item.id])]);
  };

  const submitForm = () => {
    setModalState('open');
  };

  const onCancel = () => {
    setModalState('closed');
  };

  const handleSplit = async () => {
    const items = newOrderItems.map((item) => item.id);
    const inputData: AdminSplitOrderMutationVariables['inputData'] = {
      orderId: order.id,
      lineItemIds: items,
    };
    const { adminSplitOrder: result } = await split.mutateAsync({ inputData });
    return result;
  };

  return (
    <Box padding={4} sx={{ maxWidth: '985px', margin: '0 auto' }}>
      <Box display="flex" gap={2} justifyContent="center">
        <SelectList
          checkedItems={checkedItemsLeft}
          handleChange={handleItemCheck}
          items={originalItems}
          orderId={order.friendlyId!}
          setFunction={setCheckedItemsLeft}
        />
        <Box margin="auto">
          <DirectionButton
            direction="right"
            // Disabled if nothing or everything is selected in the left table
            disabled={
              !Object.values(checkedItemsLeft).filter(Boolean).length ||
              originalItems.length === Object.values(checkedItemsLeft).filter(Boolean).length
            }
            hint={
              originalItems.length === Object.values(checkedItemsLeft).filter(Boolean).length
                ? 'Original order should have at least one item'
                : undefined
            }
            onClick={() => {
              moveToOrder(originalItems, newOrderItems, checkedItemsLeft, setOriginalItems, setNewOrderItems);
              setCheckedItemsLeft({});
            }}
          />
          <DirectionButton
            direction="left"
            // Disabled if nothing is selected in the right table
            disabled={!Object.values(checkedItemsRight).filter(Boolean).length}
            onClick={() => {
              moveToOrder(newOrderItems, originalItems, checkedItemsRight, setNewOrderItems, setOriginalItems);
              setCheckedItemsRight({});
            }}
          />
        </Box>
        <SelectList
          checkedItems={checkedItemsRight}
          handleChange={handleItemCheck}
          items={newOrderItems}
          orderId="New Order"
          setFunction={setCheckedItemsRight}
        />
      </Box>
      <Box display="flex" gap={0.5} justifyContent="flex-end" py={4}>
        <Button onClick={resetState} variant="secondary">
          Reset
        </Button>
        <Button disabled={!newOrderItems.length} onClick={() => submitForm()} variant="primary">
          Submit
        </Button>
      </Box>
      {modalState === 'open' && (
        <SubmitModal items={newOrderItems} onCancel={onCancel} onSubmit={handleSplit} order={order} />
      )}
    </Box>
  );
}
